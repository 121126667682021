/* eslint-disable */
import HttpService from '../HttpService';

export default class CancelationReasonService {
  constructor() {
    this._httpService = new HttpService('/odoo/cancelation-reason');
  }

  async FindAll(query) {
    return await this._httpService.get(query);
  }
};
