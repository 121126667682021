<template>
  <div class="d-flex align-center justify-end">
    <label class="mr-2 my-3">Legendas:</label>
    <div v-for="(legend, index) in legends" :key="index">
      <v-chip :color="legend.color" label text-color="black" class="ma-2">
        <v-icon left :color="legend.iconColor" size="15px">{{ legend.icon }}</v-icon>
        {{ legend.text }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegendsChip',
  props: { legends: Array },
};
</script>
